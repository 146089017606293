import styled from "styled-components";
import media from "../../styles/breakPoints";

import iconLangBR from "../../images/globals/lang-br.png";
import iconLangES from "../../images/globals/lang-es.png";
import iconLangEN from "../../images/globals/lang-en.png";

const ActionLanguage = styled.div`
   width: 90px;
   height: 21px;
   border: none;
   outline: none;
   display: flex;
   justify-content: space-between;
   align-items: center;

   ${media.greaterThan("lg")`
      width: 85px;
   `}

   ${media.greaterThan("xl")`
      width: 80px;
   `}
`

const ButtonLanguage = styled.a`
   width: 21px;
   height: 21px;
   outline: none;
   text-indent: -9999px;
   background-repeat: no-repeat;
   background-position: center;
   background-color: transparent;
   cursor: pointer;
   &.-active {
      transform: scale(1.1);
      border: 1px solid ${props => props.theme.color.grayClear};
      border-radius: 50%;
   }
   &.-pt {
      background-image: url(${iconLangBR});
   }
   &.-en {
      background-image: url(${iconLangEN});
   }
   &.-es {
      background-image: url(${iconLangES});
   }
`

export {
   ActionLanguage,
   ButtonLanguage
}
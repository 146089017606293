import React from "react";
import { IntlContextConsumer, 
         changeLocale } from 'gatsby-plugin-intl';

import { ActionLanguage,
         ButtonLanguage } from "./styles"

const languageName = {
   pt: "Portuguese",
   en: "English",
   es: "Spanish"
}
          
const Language = () => (
   <ActionLanguage>
      <IntlContextConsumer>
         {({ languages, language: currentLocale }) => 
            languages.map(language => (
               <ButtonLanguage   
                  key={language}
                  onClick={() => changeLocale(language)}
                  className={
                     `-${language}
                     ${currentLocale === language ? 
                     "-active" : ""}`}
               >
                  {languageName[language]}
               </ButtonLanguage>
            ))
         }
      </IntlContextConsumer>
   </ActionLanguage>
)

export default Language
import React from "react";
import { ThemeProvider } from "styled-components";
import "typeface-ubuntu";
import "typeface-open-sans";
import GlobalStyles from "../styles/globalStyles";
import theme from "../styles/projectStyles";

import TopBar from "./TopBar"
import Header from "./Header"
import Footer from "./Footer"
import ButtonToTop from "../objects/ButtonToTop"

const Layout = ({ children }) => {
   return (
      <>
         <GlobalStyles />
         <ThemeProvider theme={theme}>
            <TopBar />
            <Header />
            {children}
            <Footer />
            <ButtonToTop />
         </ThemeProvider>
      </>
   );
};

export default Layout;

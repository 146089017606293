import React from "react";
import styled from "styled-components"
import { Link } from "gatsby";

import imgLogo from "../images/globals/logo.svg"

const LinkLogo = styled(Link)`
   width: auto;
   height: 100%;
   display: flex;
   align-items: center;
`

const LabelLogo = styled.span`
   display: none;
   text-indent: -9999px;
`

const Img = styled.img`
   width: auto;
   height: 100%;
   margin: 0;
`

const Logo = ({ className }) => {

   return (
      <LinkLogo to="/" className={className}>
         <LabelLogo>Eletrosil Indústria Metalúrgica</LabelLogo>
         <Img src={imgLogo} alt="logo da Eletrosil Indústria Metalúrgica"/>
      </LinkLogo>
   )
}

export default Logo
const theme = {
  color: {
    primaryBase: "#FF9900", // base orange
    primaryDark: "#F57C00", // dark orange
    secondyBase: "#0091FF", // base blue
    secondyLight: "#E4F3FA", // light blue
    secondyDark: "#0065B2", // dark blue
    grayClear: "#FFFFFF", // white
    grayLight: "#F2F2F2", // light gray 1
    grayLight2: "#E5E5E5", // light gray 2
    grayMedium: "#757575", // medium gray
    grayDark: "#000000", // black
    error: "#FF0000", // red
  },
  font: {
    primary: `
     Ubuntu,
     Roboto,
     Helvetica,
     Arial,
     sans-serif,
     -apple-system,
     BlinkMacSystemFont`,
    secondy: `
     "Open Sans",
     Helvetica,
     Arial,
     sans-serif,
     -apple-system,
     BlinkMacSystemFont`
  },
  size: {
    verySmall: "1.4rem",
    small: "1.6rem",
    mediumSmall: "1.8rem",
    medium: "2rem",
    mediumBig: "2.2rem",
    big: "3.2rem",
    veryBig: "3.6rem"
  }
};

export default theme;

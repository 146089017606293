import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl';

import BrandIcon from "../../objects/BrandIcon"
import IconLinkedin from "../../objects/ButtonLinkedin"
import IconInstagram from "../../objects/ButtonInstagram"
import IconFacebook from "../../objects/ButtonFacebook"

import { WrapMainFooter,
         ContainerCallTo,
         WrapCallTo,
         CallTitle,
         CallDestak,
         CallContacts,
         WrapFooter,
         FooterBrand,
         FooterLocalizacao,
         FooterExpediente,
         FooterFechamos,
         FooterRedesSocials,
         FooterCopyright,
         FooterTitle,
         FooterContent } from "./styles"

const Footer = () => {

   const intl = useIntl();

   const content = useStaticQuery (
      graphql`
         query {
            contato: prismicContacts { 
               data {
                  andress { text }
                  region { text }
                  city { text }
                  state { text }
                  pabx1 { text }
                  email_vendas { text }
                  email_tecnico { text }
               }
            }
         }
      `
   )

   return(
      <WrapMainFooter id="contatos">
         <ContainerCallTo>
            <WrapCallTo>
               <CallTitle>
                  <CallDestak>
                     {intl.formatMessage({ id: "contact.title" })}
                  </CallDestak>
               </CallTitle>
               <CallContacts>
                  {content.contato.data.pabx1.text}
               </CallContacts>
               <CallContacts>
                  {content.contato.data.email_vendas.text}
               </CallContacts>
               <CallContacts>
                  {content.contato.data.email_tecnico.text}
               </CallContacts>
            </WrapCallTo>
         </ContainerCallTo>
         <WrapFooter>
            <FooterBrand>
               <BrandIcon />
            </FooterBrand>
            <FooterLocalizacao>
               <FooterTitle className="title">
                  {intl.formatMessage({ id: "contact.local" })}
               </FooterTitle>
               <FooterContent className="description">
                  {content.contato.data.andress.text}
               </FooterContent>
               <FooterContent className="description">
                  {content.contato.data.region.text}&nbsp;–&nbsp; 
                  {content.contato.data.city.text}-
                  {content.contato.data.state.text}
               </FooterContent>
            </FooterLocalizacao>
            <FooterRedesSocials>
               <FooterTitle className="title">
                  {intl.formatMessage({ id: "contact.social" })}
               </FooterTitle>
               <FooterContent className="description">
                  <IconLinkedin
                     target="https://www.linkedin.com/company/eletrosil-industria-metalurgica-ltda" />
                  <IconInstagram 
                     target="https://www.instagram.com/eletrosil_metalurgica" />
                  <IconFacebook 
                     target="https://www.facebook.com/" />
               </FooterContent>
            </FooterRedesSocials>
            <FooterExpediente>
               <FooterTitle className="title">
                  {intl.formatMessage({ id: "expediente.treatment" })}
               </FooterTitle>
               <FooterContent className="description">
                  {intl.formatMessage({ id: "expediente.working_week" })}
               </FooterContent>
               <FooterContent className="description">
                  {intl.formatMessage({ id: "expediente.working_week_dif" })}
               </FooterContent>
            </FooterExpediente>
            <FooterFechamos>
               <FooterTitle className="title">
                  {intl.formatMessage({ id: "expediente.title" })}
               </FooterTitle>
               <FooterContent className="description">
                  {intl.formatMessage({ id: "expediente.what_holiday" })}
               </FooterContent>
               <FooterContent className="description">
                  {intl.formatMessage({ id: "expediente.close_holiday" })}
               </FooterContent>
            </FooterFechamos>
            <FooterCopyright>
               <FooterContent>
                  {intl.formatMessage({ id: "rights" })}
               </FooterContent>
            </FooterCopyright>
         </WrapFooter>
      </WrapMainFooter>
   )
}

export default Footer
import React, { useState } from "react"
import styled from "styled-components"
import media from "../styles/breakPoints"

import smoothScroll from "../util/smoothScroll"
import { FaArrowCircleUp } from "react-icons/fa"

const Button = styled.button`
   display: none;
   position: fixed;
   width: 40px;
   height: 40px;
   border-radius: 50%;
   bottom: 20px;
   right: 20px;
   opacity: 0;
   transition: opacity 0.5s linear;
   z-index: 20;
   margin: 0;
   padding: 0;
   box-shadow: 1px 1px 10px ${props => props.theme.color.grayLight};
   border: none;
   outline: none;
   cursor: none;
   pointer-events: none;
   border-image-width: 0;
   font-size: 0;
   &.-visible {
      opacity: 0.5;
      transition: opacity 0.5s linear;
      cursor: pointer;
      pointer-events: visible;
      &:hover {
         opacity: 1;
         transition: opacity 0.3s linear;
      }

   }

   ${media.greaterThan("lg")`
      display: block;
   `}

`

const IconArrowUp = styled(FaArrowCircleUp)`
   width: 100%;
   height: 100%;
   stroke-width: 15;
   color: ${props => props.theme.color.primaryDark};
`

const ButtonToTop = () => {

   const [visible, setVisible] = useState("")

   const handleScroll = () => 
      window.pageYOffset >= 300 ? setVisible("-visible") : setVisible("")


   const handleClick = () => 
      typeof window !== `undefined` && smoothScroll(window, 1500, 0)

   typeof window !== `undefined` &&
      window.addEventListener("scroll", handleScroll);

   return (
      <Button className={visible} onClick={handleClick}>
         Voltar para cima
         <IconArrowUp />
      </Button>
   )
}

export default ButtonToTop
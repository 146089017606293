import React from "react";
import styled from "styled-components"
import { Link } from "gatsby";

import brandIcon from "../images/globals/logo-icon.svg"

const LinkIcon = styled(Link)`
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
`

const Img = styled.img`
   width: 100%;
   height: 100%;
   margin: 0;
`

const LogoIcon = ({ className }) => {

   return (
      <LinkIcon to="/" className={className}>
         <Img src={brandIcon} alt="ícone logo da Eletrosil"/>
      </LinkIcon>
   )
}

export default LogoIcon
import React, { useState } from "react";
import { useIntl } from 'gatsby-plugin-intl';

import { MainNav, 
         ListItem, 
         Item, 
         Action,
         ActionSpan, 
         ListSubItem, 
         SubItem, 
         SubAction, 
         IconSubAction } from "./styles"

const NavBar = ({ isActive=false, className }) => {

   const intl = useIntl();
   const locale = intl.locale !== "pt" ? `/${intl.locale}` : ""

   const [isShow, setIsShow] = useState(false);
   const [isFirst, setIsFirst] = useState(true);

   const handleHoverItemDropDown = () => (
      !isShow && setIsShow(true)
   )

   const handleClickItemDropDown = () => {
      if (isFirst) {
         setIsShow(true)
         setIsFirst(false)
      } else {
         isShow ? setIsShow(false) : setIsShow(true)
      }
   }

   const handleLeave = () => setIsShow(false)

   return (
      <MainNav className={className}>
         <ListItem className={`${isActive ? "-isActive" : "" }`} >
            <Item onMouseOver={handleLeave}> 
               <Action to={`${locale}/`}>
                  {intl.formatMessage({ id: "navbar.item1" })}
               </Action>
            </Item>
            <Item onMouseOver={handleLeave}>
               <Action to={`${locale}/empresa/`}>
                  {intl.formatMessage({ id: "navbar.item2" })}
               </Action>
            </Item>
            <Item
               onMouseOver={handleHoverItemDropDown}
               onClick={handleClickItemDropDown}
               onMouseLeave={handleLeave}>
               <ActionSpan to={`${locale}/`}>
                  {intl.formatMessage({ id: "navbar.item3" })}
                  <IconSubAction 
                     className={`${isShow ? "-isActive" : "" }`}
                  />
               </ActionSpan>
               <ListSubItem 
                  className={`${isShow ? "-isShow" : "" }`}
                  onMouseLeave={handleLeave}>
                  <SubItem>
                     <SubAction to={`${locale}/produtos/chaves-eletricas/`}>
                        {intl.formatMessage({ id: "navdropdown.item1" })}
                     </SubAction>
                  </SubItem>
                  <SubItem>
                     <SubAction to={`${locale}/produtos/contatores-reles/`}>
                        {intl.formatMessage({ id: "navdropdown.item2" })}
                     </SubAction>
                  </SubItem>
                  <SubItem>
                     <SubAction to={`${locale}/produtos/conectores-tomadas/`}>
                        {intl.formatMessage({ id: "navdropdown.item3" })}
                     </SubAction>
                  </SubItem>
                  <SubItem>
                     <SubAction to={`${locale}/produtos/diversos/`}>
                        {intl.formatMessage({ id: "navdropdown.item4" })}
                     </SubAction>
                  </SubItem>
                  <SubItem>
                     <SubAction to={`${locale}/servicos/`}>
                        {intl.formatMessage({ id: "navdropdown.item5" })}
                     </SubAction>
                  </SubItem>
               </ListSubItem>
            </Item>
            { locale === "" && 
               <Item onMouseOver={handleLeave}>
                  <Action to={`${locale}/representantes/`}>
                     {intl.formatMessage({ id: "navbar.item4" })}
                  </Action>
               </Item>
            }
            <Item onMouseOver={handleLeave}>
               <Action to={`${locale}/contatos/`}>
                  {intl.formatMessage({ id: "navbar.item5" })}
               </Action>
            </Item>
         </ListItem>
      </MainNav>
   )
}

export default NavBar;